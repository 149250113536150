body {
  min-width: 320px;
  margin: 0px;
  font-family: 'Noto Sans JP', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
